import { Controller } from "@hotwired/stimulus"
import Dropzone from "dropzone";
import "dropzone/dist/dropzone.css";

Dropzone.autoDiscover = false;
export default class extends Controller {
  connect () {
    this.addListenerForUserProfileTurboFrameRender();
  }

  initialize () {
    this.csrfToken =  document.querySelector('meta[name="csrf-token"]').getAttribute('content');
  }

  // Email Subscription Section ---->

  toggleEmailSubscription (event) {
    const isChecked = event.target.checked
    const checkboxName = event.target.name

    fetch ("/users", {
        body: JSON.stringify({ 
          user: { [checkboxName]: isChecked,
            form_type: "email_subscription"
          }
        }),
        method: 'PUT',
        credentials: "include",
        headers: {
                "Content-Type": "application/json",
                "X-CSRF-Token": this.csrfToken,
          },
    }).then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.text(); // Convert the ReadableStream to text
    })
    .then(turboStreamContent => {
      Turbo.renderStreamMessage(turboStreamContent);
    })
    .catch(function(error) {
      console.error('Error fetching data:', error);
    });
  }

  // <---- Email Subscription Section

  // Dropzone Profile Image Section ---->

  dropzoneThumbnailErrors () {
    const errorPreviews = document.querySelectorAll(".dz-image");
    errorPreviews.forEach(element => {
      element.style.border = ".2rem solid red";
    });
    
    // hide dropzone X button
    const errorMarkers = document.querySelectorAll(".dz-error-mark");
    errorMarkers.forEach(element => {
        element.style.display = "none";
    });
  }

  addListenerForUserProfileTurboFrameRender() {
    this.element.addEventListener('turbo:frame-render', this.addUserProfileDropzone.bind(this));
  }

  addUserProfileDropzone () {
    const controller = this

    // DZ element need to be within action scope
    // since frame is not rendered on initial page load
    const userProfileDropzoneElement = document.getElementById("user_profile_dropzone");

    if (userProfileDropzoneElement) {
      new Dropzone(userProfileDropzoneElement, {
        url: "/users",
        paramName: "user[account_image]",
        maxFilesize: 2,
        maxFiles: 1,
        acceptedFiles: "image/jpeg, image/png",
        addRemoveLinks: true,
        headers: { 'Accept': "text/vnd.turbo-stream.html" },
        init: function() {
          const dropzoneInstance = this;

          dropzoneInstance.on("success", function(file, response) {
            dropzoneInstance.removeFile(file);
            Turbo.renderStreamMessage(response);

            userProfileDropzoneElement.style.setProperty("display", "none");
          });
          dropzoneInstance.on("error", function(_, errorMessage, __) {
            console.error("Error uploading file:", errorMessage);
            controller.dropzoneThumbnailErrors()
          });
        },
        sending: function(_, __, formData) {
          formData.append("authenticity_token", controller.csrfToken);
          formData.append("_method", "put");
          formData.append('user[form_type]', 'profile_details_image');
        }
      });
    }  
  }

  removeUserProfileImage () {
    // DZ element need to be within action scope
    // since frame is not rendered on initial page load
    const userProfileDropzoneElement = document.getElementById("user_profile_dropzone");

    fetch('/users', {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": this.csrfToken,
        "Accept": "text/vnd.turbo-stream.html",
      },
      body: JSON.stringify({ user: { 
        account_image: "",
        form_type: 'profile_details_image'
      } } ),
    }).then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.text(); // Convert the ReadableStream to text
    }).then(turboStreamContent => {
      Turbo.renderStreamMessage(turboStreamContent);
      userProfileDropzoneElement.style.setProperty("display", "flex");
    })
  }
  // <---- Dropzone Profile Image Section
}
