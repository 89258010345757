import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.moveMenuLists();
    window.addEventListener('resize', this.resizeHandler.bind(this));
  }

  resizeHandler() {
    this.mobileFiltersActive();
    this.moveMenuLists();
  }


  mobileFiltersActive() {
    const providerFilters = document.getElementById('provider-filters');
    const mobileFilter = document.getElementById('mobile-filter');
    const map = document.getElementById('map');
    if (window.innerWidth >= 992) {
      if (providerFilters.classList.contains('mobile-filters-active')) {
        providerFilters.classList.toggle('mobile-filters-active');
        map.style.display = "none"
      } else {
        map.style.display = "block"
      }
      if (document.body.style.overflow = 'hidden') {
        document.body.style.removeProperty('overflow')
      }

      if (mobileFilter.classList.contains('activated')) {
        mobileFilter.classList.toggle('activated');
      }
    }
  }

  fixFilterList() {
    const providerFilters = document.getElementById('provider-filters');
    if (providerFilters) {
      providerFilters.classList.toggle('mobile-filters-active');
    }
  }

  scrollToTop() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  moveMenuLists() {
    const windowWidth = window.innerWidth;
    const desktopFilters = document.querySelectorAll('.desktop-filter');
    const mobileFilter = document.querySelector('.filter-drawer .menu');
    const mobileFilterActions = document.querySelector('.filter-drawer .filter-actions');
    if (windowWidth < 992) {
      desktopFilters.forEach(function (filter) {
        const currentDesktopNode = filter.querySelector('.menu');
        const currentFilter = filter.querySelector('ul');
        if (!mobileFilter.contains(currentFilter) && currentFilter !== null) {
          mobileFilter.insertBefore(currentFilter, mobileFilterActions);
        }
        if (currentDesktopNode.contains(filter)) {
          currentDesktopNode.removeChild(filter);
        }
      });
      const checkedCheckboxes = document.querySelectorAll('input[type="checkbox"]:checked, input[type="radio"]:checked');
      const filterCountElement = document.getElementById('mobile-filter-filter-count');
      if (filterCountElement && checkedCheckboxes.length > 0 ) {
        filterCountElement.textContent = " ("+ checkedCheckboxes.length.toString() + ")";
      }
    } else {
      desktopFilters.forEach(function (filter) {
        const currentDesktopNode = filter.querySelector('.menu');
        const currentFilter = filter.querySelector('ul');
        const currentDesktopCounter = filter.querySelector('.filter-counter');
        if (!currentDesktopNode.contains(currentFilter)) {
          const filterClasses = Array.from(filter.classList);
          const matchingClass = filterClasses.find(className => mobileFilter.querySelector('ul.' + className));
          if (matchingClass) {
            const matchingUl = mobileFilter.querySelector('ul.' + matchingClass);
            currentDesktopNode.insertBefore(matchingUl, currentDesktopNode.querySelector('.apply-button'));
            const filterCheckedCheckboxes = filter.querySelectorAll('input[type="checkbox"]:checked, input[type="radio"]:checked');
            const filterCheckboxCount = filterCheckedCheckboxes.length;
            console.log(filterCheckboxCount)
            if (filterCheckboxCount > 0 ){
              currentDesktopCounter.textContent = " (" + filterCheckboxCount.toString() + ")"
            }
            if (mobileFilter.contains(matchingUl)) {
              mobileFilter.removeChild(matchingUl);
            }
          }
        }
      });
    }
  }

}
